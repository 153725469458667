.credits-window {
  padding-bottom: 5px;
}

.mascot-picture {
  height: 70px;
  float: left;
  padding-right: 15px;
  padding-left: 10px;
}

.list-content {
  /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}
