.link-list {
  overflow: auto;
  overflow-y: hidden;
  color: white;
  background-color: #3968C2;
  font-weight: bold;

  font-family: Lucida Console,Lucida Sans Typewriter,monaco,Bitstream Vera Sans Mono,monospace;
}

.link-list a {
  color: yellow;

  /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}

.link-list a:hover {
  color: red;
}

.link-list a:visited {
  color: lightgray;
}

.link-style {
  padding: 3px;
}

.globe-container {
  background-color: black;
  width: 80px;
  height: 80px;
  float: right;

  margin-left: 15px;
  margin-bottom: 15px;
}

.globe-picture {
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}