.social-icon {
  margin: 5px;
}

.social-icons-container {
  padding-top: 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-info {
  padding-bottom: 10px;
  padding-top: 10px;
}