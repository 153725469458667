.jancuk-message-container {
  padding-bottom: 30px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.jancuk-message-text {
  padding-top: 10px;
  display: block;
  font-size: 1.5em;
  font-weight: bold;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.action-button-container {
  display: flex;
  justify-content: center;
}