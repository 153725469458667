.text-container {
  background: #fffee8;
  padding: 10px;

  margin-bottom: 15px;

  border: 1px solid;
  padding: 10px;
  box-shadow: 10px 10px #707070;
  font-size: 18px;
}

.avatar-picture {
  height: 210px;
  float: right;
  padding: 5px;
  margin-left: 2px;
}