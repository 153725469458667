.popup-window-container {
  position: absolute;
  z-index: 4;

  touch-action: auto !important;

  margin-left: -10px;
  margin-right: 10px;
  margin-top: -15px;
}

.popup-movable-header {
  cursor: grab;
}

.popup-movable-header:active {
  cursor: grabbing;
}