.small-icon {
  padding-right: 5px;
  height: 20px;
}

.footer-buttons {
  width: 50%;
}

.footer-cut-out {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px)  {
  .footer-buttons {
    width: 100%;
  }
}
