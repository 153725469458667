.terminal-style {
  position: absolute;
  top: 0;

  padding: 5px;

  text-shadow: 0px 0px 8px white;

  color: white;
  font-size: 18px;
  font-weight: bold;
  font-family: Lucida Console,Lucida Sans Typewriter,monaco,Bitstream Vera Sans Mono,monospace;

  cursor: url(data:image/gif;base64,R0lGODlhAQABAIABAAAAAAD/FywAAAAAAQABAAACAkQBADs=), auto;
}